import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

export const loginWithGoogle = (): void => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  signInWithPopup(auth, provider)
    .catch(() => {
      // do nothing
    });
};

export const signOut = (): Promise<void> => {
  const auth = getAuth();
  return auth.signOut();
};
