import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface InputProps{
  type?: 'text' | 'password' | 'email';
  id?: string;
  label:string;
  register: UseFormRegisterReturn;
}

export const Input: React.FC<InputProps> = (props) => {
  const {
    type = 'text',
    id,
    label,
    register,
  } = props;

  return (
    <div>
      <label htmlFor={register.name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          type={type}
          id={id}
          {...register}
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>
  );
};
