import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface AuthState {
  userUid: string | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: AuthState = {
  userUid: null,
  status: 'idle',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserUid: (state, action: PayloadAction<string | null>) => {
      state.userUid = action.payload;
    },
  },
});

export const { setUserUid } = authSlice.actions;

// selectors
export const selectUserUid = (state: RootState): string | null => state.auth.userUid;

export default authSlice.reducer;
