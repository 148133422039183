import React from 'react';
import { Calendar } from '../features/calendar/calendar';
import { Rooms } from '../features/rooms/Rooms';

export interface AppRoute {
    path: string;
    component: React.ReactNode,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    showWhen: boolean | ((...args: any) => boolean);
    menu?: 'side' | 'md' | boolean;
    title: string;
  }

export const APP_ROUTES: AppRoute[] = [
  {
    path: '/calendar',
    component: <Calendar />,
    showWhen: true,
    title: 'Calendar',
  },
  {
    path: '/rooms',
    component: <Rooms />,
    showWhen: true,
    title: 'Rooms',
  },
];
