import React from 'react';

export interface Button {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick?: (...args: any) => void;

    className?: string;
}

export const Button: React.FC<Button> = (props) => {
  const { onClick, children, className } = props;
  const defaultClasses = 'inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none';

  return (
    <button type="button" onClick={onClick} className={`${defaultClasses} ${className}`}>
      {children}
    </button>
  );
};
