import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import App from './App';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';

// initialize firebase
export const app = initializeApp({
  apiKey: 'AIzaSyCDWsh8tpXy7Rh_-v-E82VwaeQtqZDJAow',
  authDomain: 'schedulerr-c3b89.firebaseapp.com',
  projectId: 'schedulerr-c3b89',
  storageBucket: 'schedulerr-c3b89.appspot.com',
  messagingSenderId: '547025051290',
  appId: '1:547025051290:web:f5ded4319c973f41824923',
  measurementId: 'G-HFEKGPCE04',
});

getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
