import React from 'react';
import './App.css';
import Shell from './app/shell';

const App: React.FC = () => (
  <div className="App bg-gradient-to-r from-indigo-50 to-indigo-100 ">
    <Shell />
  </div>
);

export default App;
