/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { signOut } from '../app/auth/auth-api';
import { setUserUid } from '../app/auth/authSlice';
import { useAppDispatch } from '../app/hooks';
import { APP_ROUTES } from '../app/routes';
import { MenuItem } from './MenuItem';

const SideBar: React.FC<{ brand: string }> = (props) => {
  const { brand } = props;
  const dispatch = useAppDispatch();
  const doSignOut = async () => {
    await signOut();
    dispatch(setUserUid(null));
  };
  return (
    <div className="sidebar bg-blue-800 text-blue-100 w-64 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out">

      <a href="#" className="text-white flex items-center space-x-2 px-4">
        {/* <Logo /> */}
        <span className="text-2xl font-extrabold">{brand}</span>
      </a>

      <nav>
        {APP_ROUTES.map((route, i) => (
          <MenuItem key={i} to={route.path}>{route.title}</MenuItem>
        ))}

        <MenuItem
          key="signout"
          onClick={doSignOut}
        >
          Sign Out
        </MenuItem>
      </nav>
    </div>
  );
};

export default SideBar;
