import React from 'react';
import { Link } from 'react-router-dom';

export interface MenuItem {
  to?: string;
  onClick?: () => void;
}

export const MenuItem:React.FC<MenuItem> = (props) => {
  const { to = null, children, onClick } = props;
  const className = 'block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white';
  return to
    ? (
      <Link to={to} className={className}>
        {children}
      </Link>
    ) : (
      <button type="button" className={`${className} w-full text-left`} onClick={onClick}>
        {children}
      </button>
    );
};
