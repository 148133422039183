/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import {
  onAuthStateChanged, getAuth,
} from 'firebase/auth';
import { useForm } from 'react-hook-form';
import { selectUserUid, setUserUid } from './authSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { Input } from '../../components/Input';

const Auth: React.FC = (props) => {
  const { children } = props;
  const userUid = useAppSelector(selectUserUid);
  const dispatch = useAppDispatch();

  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
    },

  });

  const onSubmit = () => {
    // console.log('submitted', data);
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const { uid } = user;
        dispatch(setUserUid(uid));
      }
    });

    return () => {
      // cleanup
      unsubscribe();
    };
  }, [dispatch]);

  return userUid ? (<>{children}</>)
    : (
      <div className="min-h-screen flex items-center justify-center">
        <div className="max-w-md w-full space-y-8 space-x bg-white shadow-md rounded p-8">
          <form className="mt-8 space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
            <input type="hidden" name="remember" defaultValue="true" />

            <Input label="Email address" register={form.register('email')} type="email" />

            <Input label="Password" register={form.register('password')} type="password" />

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    );
};

export default Auth;
