import React from 'react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';
import { APP_ROUTES } from './routes';
import MobileSidebar from '../components/MobileSidebar';
import SideBar from '../components/SideBar';
import { BRANDING } from '../utils/constants';
import Auth from './auth/auth';

export const Logo: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
  </svg>
);

const Shell: React.FC = () => (
  <Auth>
    <div className="relative min-h-screen md:flex">
      <BrowserRouter>
        <MobileSidebar brand={BRANDING} />
        <SideBar brand={BRANDING} />
        <div className="bg-gray-50 flex-1 p-6">
          <Switch>
            {APP_ROUTES.map((route, i) => (route.showWhen
              && (
              // eslint-disable-next-line react/no-array-index-key
              <Route path={route.path} key={i}>
                {route.component}
              </Route>
              )
            ))}
          </Switch>
        </div>
      </BrowserRouter>
    </div>

  </Auth>
);

export default Shell;
