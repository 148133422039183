import {
  getDay, getDaysInMonth, getMonth, getYear, lastDayOfMonth, set,
} from 'date-fns';
import { startOfMonth } from 'date-fns/esm';
import React, { useState } from 'react';
import { DAY_NAMES, MONTH_NAMES } from '../../utils/constants';

const DayBox: React.FC = (props) => {
  const { children } = props;
  return <div {...props} className="rounded bg-white shadow p-3 h-32">{children}</div>;
};

const createDayBoxes = (date = new Date()) => {
  const days = getDaysInMonth(date);
  let elements = [];

  for (let ctr = 0; ctr < days; ctr++) {
    elements.push(ctr + 1);
  }

  const startDay = getDay(startOfMonth(date));
  for (let ctr = 0; ctr < startDay; ctr++) {
    elements = ['', ...elements];
  }

  const lastDay = getDay(lastDayOfMonth(date));

  for (let ctr = 0; ctr < (6 - lastDay); ctr++) {
    elements = [...elements, ''];
  }

  return elements.map((str, i) => <DayBox key={i}>{str}</DayBox>);
};

export const Calendar: React.FC = () => {
  const [date, setDate] = useState(new Date());
  const [year, setYear] = useState(getYear(new Date()));

  const changeMonth = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const month = +e.target.value;
    setDate(set(date, { month }));
  };

  const changeYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const val = +e.target.value;
    setYear(val);
    setDate(set(date, { year: val }));
  };

  return (
    <>
      <div className="w-1/4 space-x-2">
        <select onChange={changeMonth} value={getMonth(date)} className="rounded">
          {MONTH_NAMES.map((str, i) => (
            <option key={i} value={i}>
              {str}
            </option>
          ))}
        </select>

        <select onChange={changeYear} value={year} className="rounded">
          {new Array(100).fill(0).map((_, i) => (
            <option key={i} value={i + 1950}>
              {i + 1950}
            </option>
          ))}
        </select>
      </div>

      <div className="grid grid-cols-7 gap-1">
        {DAY_NAMES.map((name, i) => <div key={i}>{name}</div>)}
        {createDayBoxes(date)}
      </div>
    </>
  );
};
