import React from 'react';

export const Card: React.FC = (props) => {
  const { children } = props;
  return (
    <div className="bg-white shadow-md sm:rounded-md sm:overflow-hidden p-6">
      {children}
    </div>
  );
};
